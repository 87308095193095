import Menu from "./Menu/Menu";
import "./Order/AdminOrder/OrderList.css"
const Admin =()=>{

    return (
        <div className="container-order" >
        <div className="column-order-left">
        <Menu />

        </div>
        <div className="column-order-right">
      

        </div>
    </div>
    )


}

export default Admin;